import React from 'react';
import { useTitle } from '../Misc/Misc';
import TokenList from './AdminPortfolioComponent/TokenList';
import NetworkList from './AdminPortfolioComponent/NetworkList';
import AddNewToken from './AdminPortfolioComponent/AddNewToken';
import AddNewNetwork from './AdminPortfolioComponent/AddNewNetwork';


function AdminPortfolio(props) {
    useTitle("Gateway Exchange - Portfolio");
    return (
        <div className='w-[90%] mx-auto my-32'>
            <div className='flex flex-wrap justify-evenly'>
                <AddNewToken/>
                <TokenList/>
                <AddNewNetwork/>
                <NetworkList/>
                
            </div>
        </div>
    );
}

export default AdminPortfolio;