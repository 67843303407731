import React,{useState,useEffect} from 'react';
import { getFormValues } from '../Misc/Misc';
import GatewayLoader from '../Loader/GatewayLoader';
import { PutAPIMethodPlain } from '../../APIMethods/PutAPIMethod';

export default function PasswordResetForm({setCurrentView,email}) {
    const [error,setError] = useState(null)
    const [request,setRequests] = useState({loading:false,data:null,error:false,});


    const handleSubmit =(e)=>{
        e.preventDefault();


        const formData = getFormValues();

        if (formData["password"] === formData["confirm_password"]) {
            setError(null)
            formData['email'] = email
            PutAPIMethodPlain("reset-password/",formData,setRequests)
        }else{
            setError("Password does not match")
        }

    }

    useEffect(()=>{
        if (request.data && !request.error) {
            setCurrentView(3)

        }

    },[request,setCurrentView])


  return (
    <div className='flex flex-col space-y-10 p-5 onboarding-form-bg mt-24 max-w-lg mx-auto w-full rounded-xl'>
        {request.loading && <GatewayLoader/>}
        <div className='text-center text-white'>
            <p className='text-sm font-normal text-gray-200 md:text-base'>Please check your spam folder if you can't see the OTP. use the OTP to reset your password.</p>
        </div>

        {error && <p className='text-center text-red-400 font-medium text-sm md:text-base'>{error}</p>}
        {
            request.error && <p className='text-center text-red-400 font-medium text-sm md:text-base'>{request.data?.message}</p>
        }
        <form onSubmit={handleSubmit} className='w-full'>
            <div className="h-12 input-group md:h-14">
                <input type="number" id="OTP" name='OTP' placeholder='OTP' required className="input h-full" autoComplete='off'/>
                <label htmlFor="OTP" className="input-label">OTP</label>
            </div>

            <div className="h-12 input-group md:h-14">
                <input type="password" id="password" name='password' placeholder='your new password' required className="input h-full" autoComplete='off'/>
                <label htmlFor="password" className="input-label">New Password</label>
            </div>

            <div className="h-12 input-group md:h-14">
                <input type="password" id="confirm_password" name='confirm_password' placeholder='confirm new password' required className="input h-full" autoComplete='off'/>
                <label htmlFor="confirm_password" className="input-label">Confirm New Password</label>
            </div>

            <div className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                <button className='text-white w-full h-full font-medium text-base md:text-xl'>Change Password</button>
            </div>
        </form>
    </div>
  )
}
