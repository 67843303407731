import React,{useState,useEffect} from 'react';
import { Link,useLocation } from 'react-router-dom';

function AdminNavbar(props) {
    const { pathname } = useLocation();
    let basePath = pathname.split("/")[1];
    let currentPath = pathname.split("/")[2];
    const [showHeader,setShowHeader] = useState(false);
    const [showMobileNav,setShowMobileNav] = useState(false);

    useEffect(() => {
        const showHeaderToURLs = ["admin",];
        let show = showHeaderToURLs.includes(basePath);
        setShowHeader(show);
        if (show) {
            document.documentElement.style.background = "#fff";
        }

    }, [basePath,]);


    const closeNav = (e)=>{
        setShowMobileNav(false);
    }

    
    return (
        <>
        {
            showHeader &&
            <>
                <div className='w-full h-20 bg-[#3b3343] py-2 px-5 flex justify-between fixed z-50 left-0 right-0'>
                    <div className='max-w-[150px] w-full -ml-2'>
                        <Link to={"/"}>
                            <img src="/Image/logo.svg" alt="" className='w-full h-full'/>
                        </Link>
                    </div>

                    <div className='w-full max-w-[71rem]'>
                        <nav className='w-full h-full hidden md:block'>
                            <ul className='w-full h-full flex flex-wrap justify-evenly place-items-end'>
                                <Link to={"/admin"} className={`${!currentPath? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>Dashboard</Link>
                                <Link to={"/admin/user-trades"} className={`${currentPath === 'user-trades'? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>Trades</Link>
                                <Link to={"/admin/referrals"} className={`${currentPath === 'referrals'? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>Referral Withdrawals</Link>
                                <Link to={"/admin/portfolio"} className={`${currentPath === 'portfolio'? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>Portfolio</Link>
                                <Link to={"/admin/exchange-rate"} className={`${currentPath === 'exchange-rate'? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>Exchange Rate</Link>
                                <Link to={"/admin/user-verification"} className={`${currentPath === 'user-verification'? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>User Verification</Link>
                                <Link to={"/dashboard"} className={`${currentPath === ''? 'text-[#ec752f]':'text-white'} h-full inline-flex items-center font-medium text-base hover:text-[#FFA519] md:text-lg`}>User Dashboard</Link>
                            </ul>
                        </nav>
                        <div onClick={()=>{setShowMobileNav(!showMobileNav)}} className='grid place-content-end content-center h-full w-full text-white md:hidden'>
                            <svg className="w-7 h-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" /></svg>
                        </div>
                    </div>
                </div>

                <div className={`${showMobileNav? 'right-0':'-right-[400px]'} transition_0_5s bg-[#3b3343] max-w-[260px] w-full h-screen fixed overflow-y-scroll custom-scrollbar top-0 z-50 xl:-right-[400px]`}>
                    <div onClick={()=>{setShowMobileNav(!showMobileNav)}} className='absolute cursor-pointer top-5 right-5'>
                        <svg className="w-7 h-7" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                    </div>
                    <nav className='w-full h-full block mt-24 fixed'>
                        <ul className='w-full h-full flex flex-col space-y-8 place-items-start p-5'>
                            <Link onClick={closeNav} to={"/admin"} className={`${!currentPath? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>Dashboard</Link>
                            <Link onClick={closeNav} to={"/admin/user-trades"} className={`${currentPath === 'user-trades'? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>Trades</Link>
                            <Link onClick={closeNav} to={"/admin/referrals"} className={`${currentPath === 'referrals'? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>Referral Withdrawals</Link>
                            <Link onClick={closeNav} to={"/admin/portfolio"} className={`${currentPath === 'portfolio'? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>Portfolio</Link>
                            <Link onClick={closeNav} to={"/admin/exchange-rate"} className={`${currentPath === 'exchange-rate'? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>Exchange Rate</Link>
                            <Link onClick={closeNav} to={"/admin/user-verification"} className={`${currentPath === 'user-verification'? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>User Verification</Link>
                            <Link onClick={closeNav} to={"/dashboard"} className={`${currentPath === ''? 'text-[#ec752f] mobile-nav-actiave-bar':'text-white'} font-medium text-sm hover:text-[#FFA519] md:text-lg`}>User Dashboard</Link>
                        </ul>
                    </nav>
                </div>
            </>
        }
    </>
    );
}

export default AdminNavbar;