
export const returnDivColor = (status)=>{
    if (status === "Declined") {
        return("bg-red-600")
    } else if (status === "Pending") {
        return("bg-yellow-600")
    } else {
        return("bg-green-600")
    }
}

export const returnStatusText = (status)=>{
    if (status === "Declined") {
        return(["text-red-600","Declined"])
    } else if (status === "Pending") {
        return(["text-yellow-600","Pending"])
    } else {
        return(["text-green-600","Success"])
    }
}


export const returnStatusSVG = (status)=>{
    if (status === "Declined") {
        return(<svg className="w-10 h-10" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>)
    } else if (status === "Pending") {
        return(<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>)
    } else {
        return(<svg className="w-10 h-10" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>)
    }
}