import Footer from './Components/Navs/Footer';
import IndexHeader from './Components/Navs/IndexHeader';
import Dashboard from './Components/UserDashboard/Dashboard';
import IndexPage from './Components/IndexComponents/IndexPage';
import UserTrades from './Components/AdminDashboard/UserTrades';
import UserProfile from './Components/UserDashboard/UserProfile';
import SupportPage from './Components/UserDashboard/SupportPage';
import IndexRoutes from './Components/SpecialRoutes/IndexRoutes';
import ReferralPage from './Components/UserDashboard/ReferralPage';
import ExchangeRate from './Components/AdminDashboard/ExchangeRate';
import LoginPage from './Components/OnboardingComponents/LoginPage';
import SignUpPage from './Components/OnboardingComponents/SignUpPage';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import TradeTokenPage from './Components/UserDashboard/TradeTokenPage';
import AdminDashboard from './Components/AdminDashboard/AdminDashboard';
import AdminPortfolio from './Components/AdminDashboard/AdminPortfolio';
import AdminOnlyRoutes from './Components/SpecialRoutes/AdminOnlyRoutes';
import UserVerification from './Components/AdminDashboard/UserVerification';
import ForgotPassword from './Components/OnboardingComponents/ForgotPassword';
import ReferralWithdrawals from './Components/AdminDashboard/ReferralWithdrawals';
import PermissionBasedRoutes from './Components/SpecialRoutes/PermissionBasedRoutes';
import EditToken from './Components/AdminDashboard/AdminPortfolioComponent/EditToken';
import EditNetwork from './Components/AdminDashboard/AdminPortfolioComponent/EditNetwork';
import ActionSuccessfulPage from './Components/OnboardingComponents/ActionSuccessfulPage';



function App() {
    
    return (
        <div className="relative h-screen w-full flex flex-col justify-between">
            <Router>
                <IndexHeader/>
                <div className='relative w-full'>
                    <Routes>
                        <Route element={<IndexRoutes/>}>
                            <Route exact path='/' element={<IndexPage/>} />
                            <Route exact path='/login' element={<LoginPage/>} />
                            <Route exact path='/signup' element={<SignUpPage/>} />
                            <Route exact path='/success' element={<ActionSuccessfulPage/>} />
                            <Route exact path='/forgot-password' element={<ForgotPassword/>} />
                        </Route>

                        <Route element={<PermissionBasedRoutes/>}>
                            <Route exact path='/dashboard' element={<Dashboard/>} />
                            <Route exact path='/support' element={<SupportPage/>} />
                            <Route exact path='/trade' element={<TradeTokenPage/>} />
                            <Route exact path='/referral' element={<ReferralPage/>} />
                            <Route exact path='/my-profile' element={<UserProfile/>} />
                        </Route>

                        <Route element={<AdminOnlyRoutes/>}>
                            <Route exact path='/admin' element={<AdminDashboard/>} />
                            <Route exact path='/admin/user-trades' element={<UserTrades/>} />
                            <Route exact path='/admin/portfolio' element={<AdminPortfolio/>} />
                            <Route exact path='/admin/edit-token/:id' element={<EditToken/>} />
                            <Route exact path='/admin/exchange-rate' element={<ExchangeRate/>} />
                            <Route exact path='/admin/referrals' element={<ReferralWithdrawals/>} />
                            <Route exact path='/admin/edit-network/:id' element={<EditNetwork/>} />
                            <Route exact path='/admin/user-verification' element={<UserVerification/>} />
                        </Route>
                        
                    </Routes>
                </div>

                <Footer/>
            </Router>
        </div>
    );
}

export default App;
