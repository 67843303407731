import React from 'react';
import DashboardNav from '../Navs/DashboardNav';
import { Navigate,Outlet } from 'react-router-dom';

function PermissionBasedRoutes({redirectPath='/login',children}) {
    let isAuth = localStorage.getItem('isAuthenticated');

    if (isAuth !== 'true') {
        return(
            <Navigate to={redirectPath} replace />
        )
    }

    return(
        <>
            <DashboardNav/>
            {children ? children : <Outlet/>}
        </>
        
    )
}

export default PermissionBasedRoutes;