import React from 'react';
import { useNavigate } from 'react-router-dom';

function ActionSuccessfulPage({title,description="Please check your spam folder if you can't see it.click the link to reset your password."}) {
    const navigateTo = useNavigate();

    return (
        <div className='relative h-full py-16 md:py-28 px-2'>
            
            <div className='flex flex-col space-y-10 p-5 onboarding-form-bg mt-24 max-w-lg mx-auto w-full rounded-xl'>
                <div className='text-center text-white'>
                    <h2 className='text-2xl font-semibold md:text-3xl'>{title}</h2>
                    <p className='text-sm font-normal text-gray-200 md:text-base'>{description}</p>
                </div>

                <div onClick={()=>{navigateTo("/login")}} className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                    <button className='text-white w-full h-full font-medium text-base md:text-xl'>Return to Login</button>
                </div>
            </div>
        </div>
    );
}

export default ActionSuccessfulPage;