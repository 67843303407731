import React,{useState,useEffect} from 'react';
import BuyForm from './TradeComponents/BuyForm';
import SellForm from './TradeComponents/SellForm';
import GatewayLoader from '../Loader/GatewayLoader';
import { Link,useNavigate } from 'react-router-dom';
import TradingTerms from './TradeComponents/TradingTerms';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import {getAdvancedFormValues,useTitle } from '../Misc/Misc';
import BuyConfirmation from './TradeComponents/BuyConfirmation';
import SellConfirmation from './TradeComponents/SellConfirmation';
import { PostAPIMethodStateless } from '../../APIMethods/PostAPIMethod';
import { TradeContextProvider } from '../../ContextProviders/TradeContextProvider';

function TradeTokenPage(props) {
    const navigateTo = useNavigate()
    useTitle("Gateway Exchange - Trade Token");
    const [finalData,setFinalData] = useState([]);
    const [currentView,setCurrentView] = useState(1);
    const [currentForm,setCurrentForm] = useState('Buy');
    const [isWithinLimit,SetIsWithinLimit] = useState(false);
    const [request,setRequests] = useState({loading:false,data:null,error:false,})
    const [tradeInfo,setTradeInfo] = useState({buy_exchangerate:0,sell_exchangerate:0});

    const exchangeRate = GetAPIMethods("exchange-rate/");

    useEffect(()=>{
        if (exchangeRate.data) {
            setTradeInfo(tradeInfo=>({...tradeInfo,sell_exchangerate:exchangeRate.data?.sell_value,buy_exchangerate:exchangeRate.data?.buy_value}))
        }
    },[exchangeRate.data])



    useEffect(()=>{
        const activeView = window.location.hash;

        if (activeView) {
            setCurrentForm("Sell");
        }else{
            setCurrentForm("Buy")
        }

    },[])


    useEffect(()=>{
        if (request.data?.success) {
            navigateTo("/dashboard")
        }
    },[request.data,navigateTo])

    useEffect(() => {
        var newView = true;

        if (newView) {
            let target = document.getElementById(currentForm.toLowerCase());
            var marker = document.querySelector("#marker");
            marker.style.left = `${target.offsetLeft}px`;
            marker.style.width = `${target.offsetWidth}px`;
        }
        SetIsWithinLimit(false)
        return () => {
            newView = false
        };
    }, [currentForm]);


    const withinLimit = (tradeInfo)=>{
        let amount =0
        if (tradeInfo?.currency === "USD") {
            amount = tradeInfo?.amount
        } else {
            amount = tradeInfo?.currency_equivalent
        }

        if (amount > tradeInfo?.token?.highest_buy_limit) {
            return(true)
        } else if (tradeInfo?.token?.lowest_buy_limit > amount) {
            return(true)
        } else {
            return(false)
        }
    }

    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getAdvancedFormValues();

        var isWithinLimit = withinLimit(tradeInfo);

        if (currentForm === "Sell") {
            isWithinLimit = false;
        }

        SetIsWithinLimit(isWithinLimit)

        let tradeData = {...tradeInfo,"order":currentForm.toLowerCase()}

        if (!isWithinLimit && currentView<3 && formData?.token_name !== "" && formData?.token_network !== "") {
            setCurrentView(currentView+1);
        } else if (currentView === 3) {
            PostAPIMethodStateless("place-order/",tradeData,setRequests)
        } else {
            
        }
    }

    const viewCurrentForm =(view)=>{
        switch (view) {
            case "Sell":
                if (currentView===2) {
                    return(<TradingTerms view={view}/>)
                } else if (currentView===3) {
                    return(<SellConfirmation/>)
                }else{
                    return(<SellForm/>);
                }
            default:
                if (currentView===2) {
                    return(<TradingTerms view={view}/>)
                } else if (currentView===3) {
                    return(<BuyConfirmation/>)
                }else{
                    return(<BuyForm/>);
                }
               
        }
    }

    const setActiveBar = ((e,formName)=>{
        let target;
        setCurrentForm(formName);
        if (!e.target.id) {
            target = document.getElementById(formName.toLowerCase())
        } else {
            target = e.target
        }
        var marker = document.querySelector("#marker");
        marker.style.left = `${target.offsetLeft}px`
        marker.style.width = `${target.offsetWidth}px`
        
    });

    const buttonText = (currentForm,currentView)=>{
        if (currentView === 1) {
            return(currentForm)
        } else if (currentView === 2) {
            return("Proceed") 
        }else{
           return("Confirm Payment") 
        }
    }
    
    return (
        <div className='w-full my-32'>
            { ((exchangeRate.loading) || (request.loading)) && <GatewayLoader/>}
            <div className='max-w-xl w-full h-full p-3 bg-[#f4f4f4] order-form-box-shadow rounded-2xl md:p-5 mx-auto'>
                {
                    currentView === 1 &&
              
                    <div className='relative w-full h-11 mb-5 bg-[#dbdbdb] md:h-16'>
                        <div className='w-full flex justify-evenly h-full items-center'>
                            <Link to={"/trade"} className="h-full w-full">
                                <div onClick={(e)=>{setActiveBar(e,"Buy")}} id={"buy"} className='w-full cursor-pointer text-center h-full grid place-content-center border-r-2 border-r-[#ea7938]'>
                                    <h3 className={`${currentForm ==="Buy"?"text-white z-10":"text-black"} font-bold text-lg md:text-2xl relative h-full`}>Buy</h3>
                                </div>
                            </Link>
                            <Link to={"/trade#sell"} className="h-full w-full">
                                <div onClick={(e)=>{setActiveBar(e,"Sell")}} id={"sell"} className='w-full cursor-pointer text-center h-full grid place-content-center border-l-2 border-l-[#ea7938]'>
                                    <h3 className={`${currentForm ==="Sell"?"text-white z-10":"text-black"} font-bold text-lg md:text-2xl relative h-full`}>Sell</h3>
                                </div>
                            </Link>
                        </div>
                        <div id='marker' className='transition_0_5s absolute left-0 h-full w-0 bg-[#0b193c] bottom-0 rounded block '></div>
                    </div>
                }
                <form onSubmit={handleSubmit}>
                    <TradeContextProvider.Provider value={{
                                tradeInfo,
                                setTradeInfo,
                                finalData,
                                setFinalData
                            }}>
                        
                        {viewCurrentForm(currentForm)}
                    </TradeContextProvider.Provider>

                    {
                        isWithinLimit && <p className='font-medium text-xs text-red-500 my-3 text-center md:text-sm'>The input amount is not within the buy limits of our {tradeInfo?.token?.token_name} portfolio</p>
                    }

                    {
                        currentView === 1 &&
                        <div className='p-5 bg-[#0b193c] my-10 flex flex-col space-y-3 rounded-2xl'>
                            <div className='flex flex-row items-center justify-between'>
                                <h3 className='text-gray-300 font-medium text-xs md:text-base'>{tradeInfo?.currency === "USD"?"Equivalent in Naira":"Equivalent in USD"}</h3>
                                <h5 className='text-white font-semibold text-sm md:text-lg'>{tradeInfo?.currency === "USD"?"₦":"$"}{tradeInfo?.currency_equivalent}</h5>
                            </div>
                            <div className='flex flex-row items-center justify-between'>
                                <h3 className='text-gray-300 font-medium text-xs md:text-base'>Exchange Rate</h3>
                                <h5 className='text-white font-semibold text-sm md:text-lg'>₦{currentForm === "Buy"?tradeInfo?.buy_exchangerate:tradeInfo?.sell_exchangerate}</h5>
                            </div>
                            {
                                currentForm === "Buy" &&
                                <div className='flex flex-row items-center justify-between'>
                                    <h3 className='text-gray-300 font-medium text-xs md:text-base'>Limit</h3>
                                    <h5 className='text-white font-semibold text-sm md:text-lg'>${tradeInfo?.token?.lowest_buy_limit?.toLocaleString()} - ${tradeInfo?.token?.highest_buy_limit?.toLocaleString()}</h5>
                                </div>
                            }
                        </div>
                    }
                    <div className='bg-[#ea7938] w-full max-w-sml mx-auto h-14 rounded-2xl md:h-16'>
                        <button className='text-white w-full h-full font-medium text-base md:text-xl'>{buttonText(currentForm,currentView)} {currentView === 1? `${tradeInfo?.token?.token_name? tradeInfo?.token?.token_name:""}`:""}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default TradeTokenPage;