import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import PortfolioLoader from '../../Loader/PortfolioLoader';
import GetAPIMethods,{GetAPIMethodsStateless} from '../../../APIMethods/GetAPIMethod';


function TokenList(props) {
    const navigateTo = useNavigate()
    const tokenList = GetAPIMethods("tokens/");
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    const toggleAvailbilty =(network_name)=>{
        GetAPIMethodsStateless(`toggle-token-availabilty/${network_name}`,setRequests)
    }

    return (
        <div className='w-full max-w-lg p-5 relative'>
            { (request.loading || tokenList.loading) && <PortfolioLoader/>}
            <div className='w-full text-center mb-10'>
                <h2 className='font-bold text-lg md:text-2xl'>My Portfolio</h2>
            </div>

            <div className='flex flex-col space-y-3 w-full'>
                <div className='grid grid-cols-3 justify-evenly w-full items-center text-gray-600 font-medium text-base md:text-lg'>
                    <div>
                        <h3 className="">Token Name</h3>
                    </div>
                    <div>
                        <h3>Availability</h3>
                    </div>
                </div>

                {
                    tokenList.data?.map((token,key)=>{
                        return(
                            <div key={key} className='grid grid-cols-3 justify-evenly w-full items-center'>
                                <div>
                                    <h3 className='font-semibold text-sm md:text-base'>{token?.token_name}</h3>
                                </div>
                                <div>
                                    <input onChange={()=>{toggleAvailbilty(token?.token_name)}} type="checkbox" name="bitcoin" id="bitcoin" defaultChecked={token?.in_stock}/>
                                </div>
                                <div className='bg-[#28a745] w-full h-10 rounded-md'>
                                    <button onClick={()=>{navigateTo(`/admin/edit-token/${token?.token_name}`)}} className='w-full h-full font-bold text-white text-sm'>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
               

               

                
            </div>
        </div>
    );
}

export default TokenList;