import React from 'react';
import { Outlet } from 'react-router-dom';
import IndexHeader from '../Navs/IndexHeader';

function IndexRoutes({children}) {
    return (
        <>
            <IndexHeader/>
            {children ? children : <Outlet/>}
        </>
    );
}

export default IndexRoutes;