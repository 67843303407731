import axiosInstance from "./AxiosBase"

export default function PutAPIMethod(QUERY_URL,DATA_PARAMS,setRequests) {
    const Header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`
            
        }}
    setRequests({loading:true,data:null,error:false})
    axiosInstance.put(QUERY_URL,DATA_PARAMS,Header).then(
        
        response => {
            setRequests({loading:false,data:response.data,error:false})
        }
        ).catch((error) =>{
        setRequests({loading:false,data:error.response.data,error:true})
        })
}

export function PutAPIMethodPlain(QUERY_URL,DATA_PARAMS,setRequests) {

    setRequests({loading:true,data:null,error:false})
    axiosInstance.put(QUERY_URL,DATA_PARAMS).then(
        
        response => {
            setRequests({loading:false,data:response.data,error:false})
        }
        ).catch((error) =>{
        setRequests({loading:false,data:error.response.data,error:true})
        })

}
