import React,{useState} from 'react';
import NetworkForm from './Forms/NetworkForm';
import { getFormValues,useTitle } from '../../Misc/Misc';
import GatewayLoader from '../../Loader/GatewayLoader';
import GetAPIMethods from '../../../APIMethods/GetAPIMethod';
import { PutAPIMethodPlain } from '../../../APIMethods/PutAPIMethod';

export default function EditNetwork() {
  const [request,setRequests] = useState({loading:false,data:null,error:false,});
  const networkName = window.location.href.split("/")?.at(-1);
  useTitle(`Gateway Exchange - Edit ${networkName}`);


  const networkInfo = GetAPIMethods(`get-network-info/${networkName}`);

  const handleSubmit =(e)=>{
      e.preventDefault();
      const formData = getFormValues()
      PutAPIMethodPlain("update-network/",formData,setRequests);
  }

  return (
    <div className='w-[90%] mx-auto my-32'>
            {(networkInfo.loading || request.loading) && <GatewayLoader/>}
            <div className='w-full max-w-lg mx-auto p-5'>
                <div className='w-full text-center mb-10'>
                    <h2 className='font-bold text-lg md:text-2xl'>Edit {networkName}</h2>
                </div>
                <NetworkForm handleSubmit={handleSubmit} defaultData={networkInfo.data} editable={false} btnText={`Edit ${networkName}`}/>
            </div>
        
        </div>
  )
}
