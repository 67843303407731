import React,{useState,useEffect} from 'react';
import NetworkForm from './Forms/NetworkForm';
import GatewayLoader from '../../Loader/GatewayLoader';
import { getFormValuesFromEvent } from '../../Misc/Misc';
import { PostAPIMethodStateless } from '../../../APIMethods/PostAPIMethod';

export default function AddNewNetwork() {
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getFormValuesFromEvent(e.target)
        PostAPIMethodStateless("add-network/",formData,setRequests)
    }

    useEffect(()=>{
        if (request.data && !request.error) {
            window.location.reload()
        }
    },[request])


    return (
        <>
            {request.loading && <GatewayLoader/>}
            
            <div className='w-full max-w-lg p-5'>
                <div className='w-full text-center mb-10'>
                    <h2 className='font-bold text-lg md:text-2xl'>Add New Coin</h2>
                </div>

                <NetworkForm handleSubmit={handleSubmit}/>
            </div>
        </>
    );
}
