import React from 'react';
import { copyVerificationNumber } from '../../Misc/Misc';
import { Image_URL } from '../../../APIMethods/AxiosBase';
import { GetAPIMethodsStateless } from '../../../APIMethods/GetAPIMethod';

function UserDetails({userData,setRequests,view}) {
    
    const approveKYC = (userID) =>{
        GetAPIMethodsStateless(`approve-kyc/${userID}`,setRequests)
    }

    const declineKYC = (userID) =>{
        GetAPIMethodsStateless(`decline-kyc/${userID}`,setRequests)
    }

    const returnButtons = (userID)=>{
        if (view === "Valid") {
            return(<button onClick={()=>{declineKYC(userID)}} className='bg-[#EB5757] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Decline</button>)
        } else if (view === "Invalid") {
            return(<button onClick={()=>{approveKYC(userID)}} className='bg-[#27AE60] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Approve</button>)
        }else{
            return(
                <>
                    <button onClick={()=>{approveKYC(userID)}} className='bg-[#27AE60] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Approve</button>
                    <button onClick={()=>{declineKYC(userID)}} className='bg-[#EB5757] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Decline</button>
                </>
            )
        }
    }

    return (
        <>
            <tr className='grid grid-cols-3 p-2 justify-evenly place-items-center w-full items-center border-y-2 border-y-[#E0E0E0] md:grid-cols-6'>
                <td className='font-medium text-sm max-w-[40px] w-full overflow-hidden text-ellipsis md:max-w-[100px] md:text-base'>{userData?.user?.surname} {userData?.user?.first_name}</td>
                <td className='text-sm h-24 md:text-base'>
                    <img src={`${Image_URL}${userData?.profile_image}`} alt="proof" className='w-full h-full'/>
                </td>
                <td className='text-sm hidden md:text-base md:block'>{userData?.user?.dob}</td>
                <td className='text-sm hidden md:text-base md:block'>
                    <h3 className='font-medium'>{userData?.user?.email}</h3>
                </td>
                <td className='text-sm md:text-base'>
                    <div onClick={()=>{copyVerificationNumber(userData?.nin? userData?.nin:userData?.bvn)}} className='flex space-x-2 cursor-pointer'>
                        <svg className="w-3 h-3 md:w-5 md:h-5" fill="none" stroke="#828282" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                        <h2 className='max-w-[40px] w-full overflow-hidden text-ellipsis md:max-w-[100px]'>{userData?.nin? userData?.nin:userData?.bvn}</h2>
                    </div>
                    <p className='text-[#828282]'> {userData?.nin?"NIN":"BVN"} verification</p>
                </td>
                <td className='flex col-start-1 col-end-7 space-x-2 my-5 w-full justify-center md:col-start-auto md:col-end-auto md:space-x-0  md:space-y-2 md:flex-col'>
                    {returnButtons(userData?.user?.id)}
                    
                </td>
            </tr>
        </>
    );
}

export default UserDetails;