import React,{useState,useEffect} from 'react';

function CustomSelect({name,placeHolder,Items,onChange,defaultValue=null,disable=false}) {
    const [selected,setSelected] = useState("");
    const [toggleDropDown,setToggleDropDown] = useState(false);

    useEffect(()=>{
        if (defaultValue) {
            setSelected(defaultValue)
        }
    },[defaultValue])
    

    const selectToken =(token)=>{
        onChange(token);
        setSelected(token);
        setToggleDropDown(!toggleDropDown);
    }

    const dropdown =()=>{
        if (!disable) {
            setToggleDropDown(!toggleDropDown)
        }
    }
    
    return (
        <div className='relative w-full h-full'>
            {
                toggleDropDown && <div onClick={()=>{dropdown()}} className='dropdown-shadow'></div>
            }
            <div onClick={()=>{dropdown()}} className={`flex space-x-1 w-full h-full bg-white ${toggleDropDown? "rounded-b-none":""} rounded-2xl p-5 cursor-pointer`}>
                <div className='w-full h-full'>
                    <input type="text" value={selected} name={name} id={name} placeholder={placeHolder} readOnly autoComplete='new' className='w-full h-full font-semibold cursor-pointer outline-none text-sm bg-transparent md:text-lg'/>
                </div>
                <div className='h-full flex items-center text-gray-400 cursor-pointer'>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>
                </div>
            </div>
            {
                toggleDropDown &&
                <div className='flex flex-col space-y-2 bg-white rounded-b-2xl mt-1 max-h-52 overflow-y-scroll z-10 absolute w-full'>
                    {
                        Items?.map((item,id)=>{
                            return(
                                <div onClick={()=>{selectToken(item)}} key={id} className={`p-4 w-full cursor-pointer font-medium text-sm ${selected === item? "text-black bg-gray-200":"text-gray-400"} border-b border-b-gray-200 hover:text-black hover:bg-gray-200 md:text-lg`}>
                                    <h3>{item}</h3>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}

export default CustomSelect;