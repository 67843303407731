import React,{useState,useContext,useEffect} from 'react';
import GatewayLoader from '../../Loader/GatewayLoader';
import { copyAccountNumber,numberComma } from '../../Misc/Misc';
import { PostAPIMethodStateless } from '../../../APIMethods/PostAPIMethod';
import { TradeContextProvider } from '../../../ContextProviders/TradeContextProvider';


function BuyConfirmation(props) {
    const [imageProof,setImageProof] = useState(null);
    const {tradeInfo,setTradeInfo} = useContext(TradeContextProvider);
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    useEffect(()=>{
        PostAPIMethodStateless("payment-info/",{"order":"buy"},setRequests)
    },[tradeInfo?.network])

    const onFileInput =(e)=>{
        var src = URL.createObjectURL(e.target.files[0]);
        setImageProof(src);
        setTradeInfo((tradeInfo)=>({...tradeInfo,"payment_proof":e.target.files[0]}));
    }
    return (
        <div>
            {
                request.loading && <GatewayLoader/>
            }
            <div className='text-center'>
                <h1 className='text-[#0b193c] text-center font-semibold text-4xl md:text-6xl'>₦{tradeInfo?.currency === "NGN"?numberComma(tradeInfo?.amount):tradeInfo?.currency_equivalent}</h1>
                <p className='text-[#828282] text-sm md:text-base'>Bank transfer</p>
                <h4 className='font-bold text-sm'>Kindly pay the above amount to the payment details below</h4>
            </div>
            <div className='p-10 bg-[#0b193c] my-10 flex flex-col space-y-3 rounded-2xl'>
                <div className='flex flex-row items-center justify-between'>
                    <h3 className='text-gray-300 font-medium text-xs md:text-base'>Bank Name</h3>
                    <h5 className='text-white font-semibold text-sm md:text-lg'>{request.data?.bank_name}</h5>
                </div>
                <div className='flex flex-row items-center justify-between'>
                    <h3 className='text-gray-300 font-medium text-xs md:text-base'>Account number</h3>
                    
                    <div onClick={()=>{copyAccountNumber(request.data?.account_number)}} className='flex items-center gap-3 justify-end cursor-pointer'>
                        <svg className="w-3 h-3 md:w-6 md:h-6" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                        <h5 className='text-white font-semibold text-sm md:text-lg'>{request.data?.account_number}</h5>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-between'>
                    <h3 className='text-gray-300 font-medium text-xs md:text-base'>Account name</h3>
                    <h5 className='text-white font-semibold text-sm md:text-lg'>{request.data?.acount_name}</h5>
                </div>
            </div>
            <div className='relative w-full min-h-[4rem] border-dashed grid place-content-center border-4 border-[#DBDBDB] my-5 rounded-full'>
                {
                    !imageProof && 
                    <>
                        <input onChange={onFileInput} type="file" accept="image/*" required name="image_proof" id="image_proof"  className='absolute w-full h-full cursor-pointer opacity-0'/>
                        <h5 className='text-[#4d4747] text-center text-xs font-medium md:text-sm'>Please upload proof of payment</h5>
                    </>
                }

                {
                    imageProof &&
                    <div className='bg-gray-50 h-40 w-full rounded-xl relative'>
                        <img src={imageProof} alt={imageProof} className="w-full h-full object-contain"/>
                        <svg onClick={()=>{setImageProof(null)}} className="w-6 h-6 top-3 absolute right-3 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </div>
                }
            </div>
        </div>
    );
}

export default BuyConfirmation;