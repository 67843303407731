import {useState,useEffect} from "react";
import axiosInstance from "./AxiosBase";



function PostAPIMethods(QUERY_URL,DATA_PARAMS) {
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    useEffect(() => {
        setRequests({loading:true,data:null,error:false})

        const Header = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`
                
            }}
        
        axiosInstance.post(QUERY_URL,DATA_PARAMS,Header).then(
            
            response => {
                setRequests({loading:false,data:response.data,error:false})
            }
            ).catch(() =>{
            setRequests({loading:false,data:null,error:true})
            })

    },[DATA_PARAMS,QUERY_URL]);
    

    return (request);
}

export default PostAPIMethods;

export function AuthPost(QUERY_URL,DATA_PARAMS,setRequests) {
    // This handles user login
    setRequests({loading:true,data:null,error:false})
    const Header = {headers: {'Content-Type': 'application/json'}}//,'withCredentials':false}}
    axiosInstance.post(QUERY_URL,DATA_PARAMS,Header).then(
    
        response => {
            localStorage.setItem('access_token',response.data['access'])
            localStorage.setItem('refresh_token',response.data['refresh'])
            localStorage.setItem('isAuthenticated',true)
            localStorage.setItem('verified',response.data["verified"])
            localStorage.setItem('is_admin',response.data["is_admin"])
            axiosInstance.defaults.headers['Authorization'] = `JWT ${response.data['access']}`;
            setRequests({loading:false,data:response?.data,error:false})
        }
        ).catch((error) =>{
        setRequests({loading:false,data:error.response?.data,error:true})
        })
}

export function PostAPIMethodStateless(QUERY_URL,DATA_PARAMS,setRequests,useAUth=true) {
    
    setRequests({loading:true,data:null,error:false})

    const Header = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: useAUth?`Bearer ${localStorage.getItem['access_token']||localStorage.access_token}`:null
            
        }}

    
    axiosInstance.post(QUERY_URL,DATA_PARAMS,Header).then(
        
        response => {
            setRequests({loading:false,data:response?.data,error:false})
        }
        ).catch((error) =>{
        setRequests({loading:false,data:error.response?.data,error:true})
        })

    
}