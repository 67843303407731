import React from 'react';
import './loader.css'

export default function GatewayLoader() {
  return (
    <div className='fixed top-0 right-0 z-10 w-full h-full grid place-content-center'>
        <div className='loader-shadow'></div>
        <div className="loader"></div>
    </div>
  )
}
