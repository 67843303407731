import React,{useState,useEffect} from 'react';
import GatewayLoader from '../Loader/GatewayLoader';
import { Image_URL } from '../../APIMethods/AxiosBase';
import PutAPIMethod from '../../APIMethods/PutAPIMethod';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import { getFormValuesFromEvent,useTitle } from '../Misc/Misc';
import CustomSelect from '../HelperComponents/CustomSelectField/CustomSelect';

function UserProfile(props) {
    useTitle("Gateway Exchange - My Profile");
    const verificationMethods = ["NIN","BVN"];
    const [editKYC,setEditKYC] = useState(false);
    const [profilePic,setProfilePic] = useState(null);
    const [editProfile,setEditProfile] = useState(false);
    const [profileStatus,setprofileStatus] = useState(false);
    const [editBankDetails,setEditBankDetails] = useState(false);
    const [verificationType,setVerificationType] = useState(null);
    const Banks = ["UBA","Kuda","GTB","First Bank","Union Bank","PalmPay"];
    const [request,setRequests] = useState({loading:false,data:null,error:false,})
    const [uploadImage,setUploadImage] = useState({loading:false,data:null,error:false,})
    

    const profileData = GetAPIMethods("/my-profile/");

    useEffect(()=>{
        if (profileData?.data) {
            setprofileStatus(profileData?.data?.profile_status)
        }
    },[profileData?.data])

    const handleSubmit =(e)=>{
        e.preventDefault();
        const formData = getFormValuesFromEvent(e.target)
        PutAPIMethod("/update-profile/",formData,setRequests)
        
    }

    const onFileInput =(e)=>{
        var src = URL.createObjectURL(e.target.files[0]);
        setProfilePic(src);
        PutAPIMethod("/update-profile/",{"profile_image":e.target.files[0]},setUploadImage)    
    }

    return (
        <div className='w-full mt-32'>
            { (request.loading || uploadImage.loading || profileData.loading) && <GatewayLoader/>}

            <div className='w-full max-w-2xl mx-auto p-5 bg-gray-100 rounded-2xl flex flex-col space-y-10'>
                <div>
                    <div className='max-w-[100px] mx-auto rounded-full relative'>
                        <input type={"file"} onChange={onFileInput} accept="image/*" className='absolute w-full h-full cursor-pointer opacity-0'/>
                        {
                            profilePic?
                            <img src={profilePic} alt="user" className='w-full h-full'/>
                            :
                            <img src={profileData.data?.profile_image?`${Image_URL}${profileData.data?.profile_image}`:"/Image/DashboardImages/user.png"} alt="user" className='w-full h-full'/>
                        }
                    </div>
                    <div>
                        <h4 className='font-medium text-sm md:text-lg text-center'>{profileData?.data?.surname? profileData?.data?.surname :profileData?.data?.first_name}</h4>
                    </div>
                    {
                        !profileData.data?.verified?
                        <>
                            {profileStatus === "Invalid" && <p className='text-red-500 font-medium text-center text-xs md:text-sm'>Please submit your KYC to verify your Account</p>}
                            {profileStatus === "Pending" && <p className='text-yellow-500 font-medium text-center text-xs md:text-sm'>Your KYC is being verified</p>}
                        </>
                        :
                        <p className='text-green-500 font-medium text-center text-xs md:text-sm'>Your Account is verified</p>
                    }
                    
                    
                </div>

                <div className='w-full relative py-10 border-t-2 border-t-[#3b3343]'>

                    <div className='max-w-[200px] w-full absolute bg-[#3b3343] -top-[18px] left-3 min-h-[30px] rounded-2xl text-center'>
                        <h5 className='text-white font-medium text-base md:text-lg'>Personal Info</h5>
                    </div>

                    <form onSubmit={handleSubmit} className='flex flex-col'>
                        <div className="h-12 input-group md:h-14">
                            <input type="email" id="email" name='email' readOnly defaultValue={profileData.data?.email} placeholder="email" required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                            <label htmlFor="email" className="input-label">Email</label>
                        </div>

                        <div className="h-12 input-group md:h-14">
                            <input type="text" id="username" name='username' readOnly={!editProfile} defaultValue={profileData.data?.username} placeholder='username' className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                            <label htmlFor="username" className="input-label">Username</label>
                        </div>

                        <div className="h-12 input-group md:h-14">
                            <input type="text" id="first_name" name='first_name' readOnly={!editProfile} defaultValue={profileData.data?.first_name} placeholder='Your first name' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                            <label htmlFor="first_name" className="input-label">First Name</label>
                        </div>

                        <div className="h-12 input-group md:h-14">
                            <input type="text" id="surname" name='surname' readOnly={!editProfile} defaultValue={profileData.data?.surname} placeholder='Your surname' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                            <label htmlFor="surname" className="input-label">Surname</label>
                        </div>

                        <div className="h-12 input-group md:h-14">
                            <input type="date" id="dob" name='dob' readOnly={!editProfile} defaultValue={profileData.data?.dob} placeholder='Your date of birth' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                            <label htmlFor="dob" className="input-label">Date of birth</label>
                        </div>

                        <div className='max-w-sm w-full h-14 mx-auto flex flex-row space-x-5'>
                            <div onClick={()=>{setEditProfile(!editProfile)}} className='bg-[#ea7938] w-full h-full grid place-content-center cursor-pointer text-white w-full h-full font-medium text-base md:text-xl rounded-2xl md:h-16'>
                                {editProfile? "Cancel": "Edit Profile"}
                            </div>
                            {
                                editProfile &&

                                <div className='border-[#3b3343] border-2 w-full h-full rounded-2xl md:h-16'>
                                    <button className='text-[#3b3343] w-full h-full font-medium text-base md:text-xl'>Save Profile</button>
                                </div>
                            }
                        </div>

                    </form>
                </div>

                <div className='w-full relative py-10 border-t-2 border-t-[#3b3343]'>

                    <div className='max-w-[200px] w-full absolute bg-[#3b3343] -top-[18px] left-3 min-h-[30px] rounded-2xl text-center'>
                        <h5 className='text-white font-medium text-base md:text-lg'>Bank Details</h5>
                    </div>

                    <form onSubmit={handleSubmit} className='flex flex-col'>
                        <div className='h-16 w-full relative mb-10'>
                            <CustomSelect name={"Bank_name"} defaultValue={profileData.data?.Bank_name} Items={Banks} onChange={()=>{}} placeHolder={"Please select your bank"} disable={!editBankDetails}/>
                        </div>

                        <div className="h-12 input-group md:h-14">
                            <input type="number" id="Account_number" name='Account_number' readOnly={!editBankDetails} defaultValue={profileData.data?.Account_number} placeholder='Your Account Number' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                            <label htmlFor="Account_number" className="input-label">Account Number</label>
                        </div>

                        <div className='max-w-sm w-full h-14 mx-auto flex flex-row space-x-5'>
                            <div onClick={()=>{setEditBankDetails(!editBankDetails)}} className='bg-[#ea7938] w-full h-full grid place-content-center cursor-pointer text-white w-full h-full font-medium text-base md:text-xl rounded-2xl md:h-16'>
                                {editBankDetails? "Cancel": "Edit Bank Details"}
                            </div>
                            {
                                editBankDetails &&

                                <div className='border-[#3b3343] border-2 w-full h-full rounded-2xl md:h-16'>
                                    <button className='text-[#3b3343] w-full h-full font-medium text-base md:text-xl'>Save</button>
                                </div>
                            }
                        </div>

                    </form>

                </div>


                {
                    profileStatus === "Invalid" &&
                

                    <div className='w-full relative py-10 border-t-2 border-t-[#3b3343]'>

                        <div className='max-w-[200px] w-full absolute bg-[#3b3343] -top-[18px] left-3 min-h-[30px] rounded-2xl text-center'>
                            <h5 className='text-white font-medium text-base md:text-lg'>KYC Details</h5>
                        </div>

                        <form onSubmit={handleSubmit} className='flex flex-col'>
                            <div className='h-16 w-full relative mb-10'>
                                <CustomSelect name={"verification_type"} Items={verificationMethods} onChange={setVerificationType} disable={!editKYC}  placeHolder={"Please select your preffered verification method"}/>
                            </div>

                            {
                                verificationType === "NIN" &&
                                <div className="h-12 input-group md:h-14">
                                    <input type="number" id="nin" name='nin' placeholder='National Identification Number' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                                    <label htmlFor="nin" className="input-label">National Identification Number</label>
                                </div>
                            }
                            {
                                verificationType === "BVN" &&
                                <div className="h-12 input-group md:h-14">
                                    <input type="number" id="BVN" name='BVN' placeholder='Bank Verification Number' required className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg' autoComplete='off'/>
                                    <label htmlFor="BVN" className="input-label">Bank Verification Number</label>
                                </div>
                            }

                            <div className='max-w-sm w-full h-14 mx-auto flex flex-row space-x-5'>
                                <div onClick={()=>{setEditKYC(!editKYC)}} className='bg-[#ea7938] w-full h-full grid place-content-center cursor-pointer text-white w-full h-full font-medium text-base md:text-xl rounded-2xl md:h-16'>
                                    {editKYC? "Cancel": "KYC"}
                                </div>
                                {
                                    editKYC &&

                                    <div className='border-[#3b3343] border-2 w-full h-full rounded-2xl md:h-16'>
                                        <button className='text-[#3b3343] w-full h-full font-medium text-base md:text-xl'>Submit</button>
                                    </div>
                                }
                            </div>
                        </form>

                    </div>

                }


            </div>
        </div>
    );
}

export default UserProfile;