import React from 'react';
import { Outlet,Navigate } from 'react-router-dom';
import AdminNavbar from '../Navs/AdminNavbar';

function AdminOnlyRoutes({redirectPath='/login',children}) {
    const is_admin = localStorage.getItem('is_admin')


    if (is_admin !== 'true') {
        return(
            <Navigate to={redirectPath} replace />
        )
    }
    
    return (
        <>
            <AdminNavbar/>
            {children ? children : <Outlet/>}
        </>
    );
}

export default AdminOnlyRoutes;