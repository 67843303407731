import React from 'react';
import BuyTableInfo from './OrderInfo/BuyTableInfo';


function BuyOrderView({orderData,view}) {
    return (
        <>
            <table className='w-full'>
                <thead className='bg-[#FAFAFA] w-full h-20 flex place-content-center p-5'>
                    <tr className='grid grid-cols-5 justify-evenly w-full h-full items-center text-xs md:text-base md:grid-cols-7'>
                        <th>Customer Name</th>
                        <th>Proof</th>
                        <th>Coin</th>
                        <th>Amount</th>
                        <th>Address</th>
                        <th className='hidden md:block'>Timestamp</th>
                    </tr>
                </thead>
                <tbody className='w-full'>
                   {
                    orderData?.map((item,key)=>{
                        return(<BuyTableInfo data={item} key={key} view={view}/>)
                    })
                   }
                </tbody>

            
            </table>
            
        </>
    );
}

export default BuyOrderView;