import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import PortfolioLoader from '../../Loader/PortfolioLoader';
import GetAPIMethods,{GetAPIMethodsStateless} from '../../../APIMethods/GetAPIMethod';

function NetworkList(props) {
    const navigateTo = useNavigate()
    const networkList = GetAPIMethods("network-list/");
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    

    const toggleAvailbilty =(network_name)=>{
        GetAPIMethodsStateless(`toggle-network-availabilty/${network_name}`,setRequests)
    }

    return (
        
        <div className='w-full max-w-lg p-5 relative'>
            { (request.loading || networkList.loading) && <PortfolioLoader/>}
            <div className='w-full text-center mb-10'>
                <h2 className='font-bold text-lg md:text-2xl'>My Network</h2>
            </div>

            <div className='flex flex-col space-y-3 w-full'>
                <div className='grid grid-cols-3 justify-evenly w-full items-center text-gray-600 font-medium text-base md:text-lg'>
                    <div>
                        <h3 className="">Network Name</h3>
                    </div>
                    <div>
                        <h3>Availability</h3>
                    </div>
                </div>

                {
                    networkList.data?.map((network,key)=>{
                        return(
                            <div key={key} className='grid grid-cols-3 justify-evenly w-full items-center'>
                                <div>
                                    <h3 className='font-semibold text-sm md:text-base'>{network?.network_name}</h3>
                                </div>
                                <div>
                                    <input onChange={()=>{toggleAvailbilty(network?.network_name)}} type="checkbox" name="BEP20" id="BEP20" defaultChecked={network?.available}/>
                                </div>
                                <div className='bg-[#28a745] w-full h-10 rounded-md'>
                                    <button onClick={()=>{navigateTo(`/admin/edit-network/${network?.network_name}`)}} className='w-full h-full font-bold text-white text-sm'>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
               


                
                
            </div>
        </div>
    );
}

export default NetworkList;