import React from 'react';
import './analyticsloader.css';

export default function AnalyticsLoader() {
  return (
    <div className='bg-[#b5b1b12e] relative top-0 right-0 w-full h-full grid place-content-center'>
        <div className="loader-analytics"></div>
    </div>
  )
}
