import React from 'react';
import { returnDivColor,returnStatusText,returnStatusSVG } from './dashboardmisc';

function BuyPopUp({data,onClick}) {

    return (
        <>
            <div className={`w-full max-w-sm mx-auto ${returnDivColor(data?.status)} fixed top-0 bottom-0 right-0 left-0 mt-[120px] z-[10000] rounded-3xl h-[480px]`}>
                <div className='flex justify-end p-2 w-full'>
                    <svg onClick={onClick}  className="w-10 h-10 cursor-pointer" fill="none" stroke="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                </div>
                <div className='flex justify-between items-center space-x-4 h-24 w-full p-5'>
                    <div className='w-full h-full'>
                        <h3 className='font-semibold text-lg md:text-2xl'>Order {data?.status}</h3>
                        <p className='font-medium text-sm md:text-base'>Order to buy ${data?.dollar_equivalent.toLocaleString()} worth of {data?.coin_name} was declined</p>
                    </div>

                    <div className='flex items-start'>
                        
                        {returnStatusSVG(data?.status)}
                        
                    </div>
                </div>

                <div className='bg-[#1a1d24] w-full rounded-3xl mt-5 p-5'>
                    <div className='flex justify-between'>
                        <div>
                            <h3 className='font-medium text-white text-sm md:text-base'><span className='text-green-600'>BUY</span> {data?.coin_name}</h3>
                        </div>
                        <div>
                            <h5 className={`${returnStatusText(data?.status)[0]} font-semibold text-xs md:text-sm`}>{returnStatusText(data?.status)[1]}</h5>
                        </div>
                    </div>

                    <div className='flex flex-col space-y-5 border-b-2 border-b-gray-400 py-5'>
                        <div className='flex justify-between w-full items-center'>
                            <h3 className='text-gray-400 font-normal text-xs md:text-sm'>USD Value</h3>
                            <h3 className='text-white font-semibold text-xs md:text-sm'>${data?.dollar_equivalent.toLocaleString()}</h3>
                        </div>
                        <div className='flex justify-between w-full items-center'>
                            <h3 className='text-gray-400 font-normal text-xs md:text-sm'>Naira Value</h3>
                            <h3 className='text-white font-semibold text-xs md:text-sm'>₦{data?.naira_equivalent.toLocaleString()}</h3>
                        </div>
                        <div className='flex justify-between w-full items-center'>
                            <h3 className='text-gray-400 font-normal text-xs md:text-sm'>Network</h3>
                            <h3 className='text-white font-semibold text-xs md:text-sm'>{data?.network}</h3>
                        </div>
                        <div className='flex justify-between w-full items-center'>
                            <h3 className='text-gray-400 font-normal text-xs md:text-sm'>Address</h3>
                            <h3 className='text-white font-semibold text-xs md:text-sm max-w-[100px] w-full text-ellipsis overflow-hidden'>{data?.wallet_address}</h3>
                        </div>
                    </div>
                    <div className='flex flex-col space-y-5 py-5'>
                        <div className='flex justify-between w-full items-center'>
                            <h3 className='text-gray-400 font-normal text-xs md:text-sm'>Order ID</h3>
                            <h3 className='text-white font-semibold text-right text-xs md:text-sm'>{data?.order_id}</h3>
                        </div>
                        <div className='flex justify-between w-full items-center'>
                            <h3 className='text-gray-400 font-normal text-xs md:text-sm'>Created Time</h3>
                            <h3 className='text-white font-semibold text-xs md:text-sm'>{data?.date_placed}</h3>
                        </div>
                        {data?.status !== "Pending" &&
                            <div className='flex justify-between w-full items-center'>
                                <h3 className='text-gray-400 font-normal text-xs md:text-sm'>Completed Time</h3>
                                <h3 className='text-white font-semibold text-xs md:text-sm'>{data?.date_completed}</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default BuyPopUp;