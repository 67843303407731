import React from 'react';

function TradingTerms({view}) {
    return (
        <div>
            <div className='mb-10'>
                <h2 className='text-center font-semibold text-lg md:text-2xl'>Please, read the instructions below</h2>
            </div>

            <ul className='flex flex-col space-y-5 p-5 md:p-10'>
                {
                    view === "Sell"?
                    <>
                        <li className='text-sm list-disc list-outside md:text-base'>Please note that due to price fluctuations, there may be a slight difference between the amount you receive and the estimated amount.</li>
                        <li className='text-sm list-disc list-outside md:text-base'>Screenshot is compulsory and only Screenshots from payment app is accepted.</li>
                        <li className='text-sm list-disc list-outside md:text-base'>Opening many orders without making deposits is not allowed.</li>
                    </>
                    :
                    <>
                        <li className='text-sm list-disc list-outside md:text-base'>Third party payments are not allowed. Payments must be made from your personal account, matching your verified name on your Gateway profile.</li>
                        <li className='text-sm list-disc list-outside md:text-base'>For a successful transaction, do not enter any crypto related terms (BTC, USDT, etc.) in your payment narration.</li>
                        <li className='text-sm list-disc list-outside md:text-base'>Failure to comply with the above stated terms leads to limitation on your Gateway account and total loss of paid amount.</li>
                    </>
                }
            </ul>

            <div className='flex items-center space-x-3 w-full my-5 place-content-center'>
                <input type="checkbox" required name="agree" id="agree" />
                <p className='text-sm md:text-base'>I agree to the instructions above</p>
            </div>
        </div>
    );
}

export default TradingTerms;