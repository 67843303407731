import React,{useState} from 'react';
import GatewayLoader from '../Loader/GatewayLoader';
import { useTitle,getAdvancedFormValues } from '../Misc/Misc';
import { PostAPIMethodStateless } from '../../APIMethods/PostAPIMethod';

function SupportPage(props) {
    useTitle("Gateway Exchange - Support");
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    const handleSubmit =(e)=>{
        e.preventDefault();

        const formData = getAdvancedFormValues();
        PostAPIMethodStateless('contact-support/',formData,setRequests)

    }

    return (
        <div className='w-full mt-32'>
            {request.loading && <GatewayLoader/>}
            <div className='flex flex-col space-y-10 p-5 bg-[#3b3343] mt-24 max-w-lg mx-auto w-full rounded-xl'>

                <div className='text-center text-white'>
                    <h2 className='text-2xl font-semibold md:text-3xl'>Need Help?</h2>
                    <p className='text-sm font-normal text-gray-200 md:text-base'>We are here to help, let's fix it for you</p>
                </div>

                { (request.data && !request.error) && <p className='text-center text-sm text-green-500'>Message has been sent</p>}
                <form onSubmit={handleSubmit} className='w-full'>
                    <div className="h-12 input-group md:h-14">
                        <input type="text" id="subject" name='subject' placeholder='subject' required className="input h-full" autoComplete='off'/>
                        <label htmlFor="email" className="input-label">Subject</label>
                    </div>

                    <div className="min-h-[100px] input-group">
                        <textarea name="detail" id="detail" required placeholder="If you don't mind we would like to get a full detail of your problem" className="input h-full resize-none"></textarea>
                        <label htmlFor="email" className="input-label">Details</label>
                    </div>

                    <div className='bg-[#ea7938] w-full max-w-sm mx-auto h-14 rounded-2xl md:h-16'>
                        <button className='text-white w-full h-full font-medium text-base md:text-xl'>Submit</button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default SupportPage;