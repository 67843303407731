import React,{useState,useEffect} from 'react';
import AnalyticsLoader from '../../Loader/AnalyticsLoader';
import GetAPIMethods from '../../../APIMethods/GetAPIMethod';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import {Bar } from 'react-chartjs-2';
import { options } from './BarChartConfig/Config';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );


function NumberOfTrades(props) {
    const [chartData,setChartData] = useState(null);
    const [filterBy,setFilterBy] = useState("Weekly");
    const numberOfTrades = GetAPIMethods(`get-number-of-trades/?filter_trade_volume_by=${filterBy}`);


    useEffect(()=>{
        let data;

        const labels = numberOfTrades.data?.labels;

        data = {
            labels:labels,
            datasets: [
                {
                data: numberOfTrades.data?.data,
                backgroundColor: '#E0E0E0',
                fill: false,
                hoverBackgroundColor: '#E8730C',
                hoverBorderWidth : '0'
                },
            ],
        };
        setChartData(data);
      },[numberOfTrades.data]);

    
    return (
        <div className='w-full maxd-w-lg overflow-y-hidden admin-box-shadow p-6 rounded-md'>
            <div className='w-full text-center mb-5'>
                <h3 className='font-semibold text-lg md:text-xl'>No of trades</h3>
            </div>
            <div className='flex items-center justify-end mb-5'>
                <select onChange={(e)=>{setFilterBy(e.target.value)}} defaultValue={filterBy} name="filter" id="filter" className='max-w-[100px] w-full font-semibold text-sm outline-none h-11 px-5 rounded-2xl appearance-none cursor-pointer md:max-w-[120px] md:h-14 md:text-base'>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Per-annum">Yearly</option>
                </select>
            </div>
            {
                numberOfTrades.loading?
                <AnalyticsLoader/>
                :
                <>
                    {
                        chartData && <Bar options={options} data={chartData} width={"100%"} height={"100%"}/>
                    }
                </>
            }
        </div>
    );
}

export default NumberOfTrades;