import React,{useState,useContext,useEffect} from 'react';
import GatewayLoader from '../../Loader/GatewayLoader';
import GetAPIMethods from '../../../APIMethods/GetAPIMethod';
import CustomSelect from '../../HelperComponents/CustomSelectField/CustomSelect';
import { TradeContextProvider } from '../../../ContextProviders/TradeContextProvider';
import CustomSelectToken from '../../HelperComponents/CustomSelectField/CustomSelectToken';
import { numberOnly,prettyNumberInput,returCurrencyEquivalent,numberComma} from '../../Misc/Misc';

function SellForm(props) {
    const [Tokens,setTokens] = useState([]);
    const [tokenAmount,setTokenAmount] = useState(0);
    const [tokenNetworks,setTokenNetworks] = useState([]);
    const [selectedBank,setSelectedBank] = useState(null);
    const [selectedToken,setSelectedToken] = useState("");
    const [fiatCurrency,setFiatCurrency] = useState('USD');
    const [selectedNetwork,setSelectedNetwork] = useState("");
    const {tradeInfo,setTradeInfo} = useContext(TradeContextProvider);
    const Banks = ["UBA","Kuda","GTB","First Bank","Union Bank","PalmPay"];

    const TokenList = GetAPIMethods("/token-list?order=sell");

    useEffect(()=>{
        if (TokenList.data && !TokenList.error) {
            setTokens(TokenList.data)
        }
    },[TokenList])

    useEffect(()=>{
        setSelectedNetwork("");
        setTokenNetworks(selectedToken?.network);
        
    },[selectedToken])


    useEffect(()=>{
        setTradeInfo((tradeInfo)=>({...tradeInfo,"currency":fiatCurrency,"bank":selectedBank,"network":selectedNetwork,"token":selectedToken,"amount":tokenAmount}))
    },[selectedToken,selectedNetwork,fiatCurrency,setTradeInfo,tokenAmount,selectedBank]);


    useEffect(() => {
        let swapped = true;
        var amount = tradeInfo?.amount?tradeInfo?.amount:0;
        
        if (swapped) {
            let currencyEquivalent = returCurrencyEquivalent(fiatCurrency,tradeInfo?.sell_exchangerate,amount);
            setTradeInfo((tradeInfo)=>({...tradeInfo,"currency_equivalent":numberComma(currencyEquivalent),}))
        }

        return () => {
            swapped = false;
        };
    }, [fiatCurrency,tradeInfo?.amount,setTradeInfo,tradeInfo?.sell_exchangerate]);


    const handleOnchangeAmount =(e)=>{
        let value = e.target.value.replace(/,/g,"");
        numberOnly(e);
        prettyNumberInput(e);
        setTokenAmount(value);
        let currencyEquivalent = returCurrencyEquivalent(fiatCurrency,tradeInfo?.sell_exchangerate,value);
        setTradeInfo((tradeInfo)=>({...tradeInfo,"currency_equivalent":numberComma(currencyEquivalent),}))
    }

    const handleOnchangeAccountNumber =(e)=>{
        numberOnly(e);
        setTradeInfo((tradeInfo)=>({...tradeInfo,"account_number":e.target.value}))
        
    }


    return (
        <>
            {
                TokenList.loading && <GatewayLoader/>
            }
            <div className='flex flex-col space-y-10 w-full'>
                <div className='h-16 w-full relative'>
                    <CustomSelectToken name={"token_name"} Items={Tokens} onChange={setSelectedToken} placeHolder={"Please select a token"}/>
                </div>

                <div className='h-16 w-full relative mb-5'>
                    <div>
                        <input onChange={handleOnchangeAmount} type="text" name='amount' id='amount' required placeholder='Enter amount to buy in NGN or USD'  className='w-full h-16 p-5 outline-none bg-transparelnt rounded-2xl font-semibold text-base md:text-lg'/>
                    </div>
                    <div className='absolute top-0 bottom-0 h-full w-auto right-5 grid place-content-center'>
                        <select value={fiatCurrency} onChange={(e)=>{setFiatCurrency(e.target.value)}} name="currency" id="currency" className='outline-none bg-transparenht cursor-pointer bg-[] p-3 rounded-2xl font-extrabold text-sm md:text-lg'>
                            <option value="USD">USD</option>
                            <option value="NGN">NGN</option>
                        </select>
                    </div>
                </div>

                <div className='h-16 w-full relative'>
                    <CustomSelect name={"token_network"} Items={tokenNetworks} onChange={setSelectedNetwork} placeHolder={"Please select token network"}/>
                </div>

                <div className='h-16 w-full relative'>
                    <CustomSelect name={"bank_name"} Items={Banks} onChange={setSelectedBank} placeHolder={"Please select a bank"}/>
                </div>

                <div className='h-16 w-full relative'>
                    <input onChange={handleOnchangeAccountNumber} type="text" name='account_number' maxLength={10} id='account_number' required placeholder='Enter your account number' className='w-full h-full p-5 outline-none bg-transpakrent rounded-2xl font-semibold text-base md:text-lg'/>
                </div>
            
            </div>

        </>
    );
}

export default SellForm;