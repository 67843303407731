import React,{useState,useEffect} from 'react';
import AnalyticsLoader from '../../Loader/AnalyticsLoader';
import GetAPIMethods from '../../../APIMethods/GetAPIMethod';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import {Bar } from 'react-chartjs-2';
import { options } from './BarChartConfig/Config';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

function TradeVolume(props) {
    const [chartData,setChartData] = useState(null);
    const [filterBy,setFilterBy] = useState("Weekly");
    
    const tradeVolume = GetAPIMethods(`get-trade-volume/?filter_trade_volume_by=${filterBy}`);

    useEffect(()=>{
        let data;

        const labels = tradeVolume.data?.labels;

        data = {
            labels:labels,
            datasets: [
                {
                    label: 'Buy',
                    data: tradeVolume.data?.buy,
                    backgroundColor: '#008000',
                    fill: false,
                    hoverBackgroundColor: '#008000',
                    hoverBorderWidth : '0'
                },
                {
                    label: 'Sell',
                    data: tradeVolume.data?.sell,
                    backgroundColor: '#ff0000',
                    fill: false,
                    hoverBackgroundColor: '#ff0000',
                    hoverBorderWidth : '0'
                    },
            ],
        };
        setChartData(data);
      },[tradeVolume.data]);

    return (
        <div className='w-full max-sw-lg admin-box-shadow p-6 rounded-md'>
            <div className='w-full text-center mb-5'>
                <h3 className='font-semibold text-lg md:text-xl'>Trade Volume</h3>
            </div>
            <div className='flex items-center justify-end mb-5'>
                <select onChange={(e)=>{setFilterBy(e.target.value)}}  defaultValue={filterBy} name="filter" id="filter" className='max-w-[100px] w-full font-semibold text-sm outline-none h-11 px-5 rounded-2xl appearance-none cursor-pointer md:max-w-[120px] md:h-14 md:text-base'>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Per-annum">Yearly</option>
                </select>
            </div>
            
             {
                tradeVolume.loading?
                <AnalyticsLoader/>
                :
                <>
                    {
                        chartData && <Bar options={options} data={chartData} width={"100%"} height={"100%"}/>
                    }
                </>
            }
        </div>
    );
}

export default TradeVolume;