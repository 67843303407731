import React,{useState,useEffect} from 'react';
import { useTitle } from '../Misc/Misc';
import { useLocation,Link } from 'react-router-dom';
import GatewayLoader from '../Loader/GatewayLoader';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import WithdrawalDetails from './WithdrawalsComponet/WithdrawalDetails';

export default function ReferralWithdrawals() {
    useTitle("Gateway Exchange - Referral Withdrawals");

    const search = useLocation().search;
    const [filterBy,setFilterBy] = useState("Pending");
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    const withdrawalList = GetAPIMethods(`withdrawal-requests/?status=${filterBy}`);
    
    useEffect(()=>{
        const params = new URLSearchParams(search);
        const status = params.get('status');

        if (status) {
            setFilterBy(status)
        }
       
    },[search])

    useEffect(()=>{
        if (request.data?.success) {
            window.location.reload()
        }
    },[request.data])

  return (
    <div className='w-full mx-auto my-32 md:w-[90%]'>
            {(withdrawalList.loading || request.loading) && <GatewayLoader/>}
            <div className='w-full mt-11'>
                <div className='w-full'>
                    <div className='flex justify-evenly max-w-lg items-center place-content-center'>
                        <Link to={`?status=Pending`}>
                            <div onClick={()=>{setFilterBy("Pending")}} className={`${filterBy === "Pending"?"border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Pending ({withdrawalList.data?.pending})</h3>
                            </div>
                        </Link>

                        <Link to={`?status=Completed`}>
                            <div onClick={()=>{setFilterBy("Completed")}} className={`${filterBy === "Completed"?"border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Approved ({withdrawalList.data?.valid})</h3>
                            </div>
                        </Link>

                        <Link to={`?status=Invalid`}>
                            <div onClick={()=>{setFilterBy("Invalid")}} className={`${filterBy === "Invalid"?"border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Declined ({withdrawalList.data?.invalid})</h3>
                            </div>
                        </Link>
                    </div>
                </div>

                <table className='w-full'>
                <thead className='bg-[#FAFAFA] w-full h-20 flex place-content-center p-5'>
                    <tr className='grid grid-cols-3 justify-evenly w-full h-full items-center text-xs md:text-base md:grid-cols-5'>
                        <th>Customer Name</th>
                        <th>Account Number</th>
                        <th className='hidden md:block'>Bank Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody className='w-full'>
                    {
                        withdrawalList.data?.requests?.map((withdrawal,key)=>{
                            return(<WithdrawalDetails referralData={withdrawal} setRequests={setRequests} key={key}/>)
                        })
                    }

                </tbody>

            
            </table>

            </div>
            
        </div>
  )
}
