import React,{useState,useEffect} from 'react';
import { copyAddress } from '../../../Misc/Misc';
import GatewayLoader from '../../../Loader/GatewayLoader';
import { Image_URL } from '../../../../APIMethods/AxiosBase';
import { GetAPIMethodsStateless } from '../../../../APIMethods/GetAPIMethod';


function BuyTableInfo({data,view}) {
    const [request,setRequests] = useState({loading:false,data:null,error:false,})

    const approveTransaction = (orderID) =>{
        GetAPIMethodsStateless(`approve-transaction/${orderID}`,setRequests)
    }

    const declineTransaction = (orderID) =>{
        GetAPIMethodsStateless(`decline-transaction/${orderID}`,setRequests)
    }

    useEffect(()=>{
        if (request.data?.success) {
            window.location.reload()
        }
    },[request.data])

    const returnButtons = (orderID)=>{
        if (view === "Completed") {
            return(<button onClick={()=>{declineTransaction(orderID)}} className='bg-[#EB5757] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Decline</button>)
        } else if (view === "Declined") {
            return(<button onClick={()=>{approveTransaction(orderID)}} className='bg-[#27AE60] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Approve</button>)
        }else{
            return(
                <>
                    <button onClick={()=>{approveTransaction(orderID)}} className='bg-[#27AE60] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Approve</button>
                    <button onClick={()=>{declineTransaction(orderID)}} className='bg-[#EB5757] text-white font-bold text-sm h-10 w-full rounded-md cursor-pointer md:w-20'>Decline</button>
                </>
            )
        }
    }
    
    return (
        <>
            {request.loading && <GatewayLoader/>}
            <tr className='grid grid-cols-5 p-2 justify-evenly place-items-center w-full items-center border-y-2 border-y-[#E0E0E0] md:grid-cols-7'>
                <td className='font-medium text-sm max-w-[40px] w-full overflow-hidden text-ellipsis md:max-w-[100px] md:text-base'>{data?.order?.client_name}</td>
                <td className='text-sm h-24 md:text-base'>
                    <img src={`${Image_URL}${data?.order?.proof?.image_proof}`} alt="proof" className='w-full h-full'/>
                </td>
                <td className='text-sm md:text-base'>{data?.order?.coin_name}</td>
                <td className='text-sm md:text-base'>
                    <h3 className='font-medium'>${data?.order?.dollar_equivalent?.toLocaleString()}</h3>
                    <p className='text-[#828282] hidden md:block'>₦{data?.order?.naira_equivalent?.toLocaleString()}</p>
                </td>
                <td className='text-sm md:text-base'>
                    <div onClick={()=>{copyAddress(data?.wallet_address)}} className='flex space-x-2 cursor-pointer'>
                        <svg className="w-3 h-3 md:w-5 md:h-5" fill="none" stroke="#828282" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
                        <h2 className='max-w-[40px] w-full overflow-hidden text-ellipsis md:max-w-[100px]'>{data?.wallet_address}</h2>
                    </div>
                    <p>{data?.order?.network}</p>
                </td>
                
                <td className='text-sm hidden md:block md:text-base'>{data?.order?.date_completed}</td>
                <td className='flex col-start-1 col-end-7 space-x-2 my-5 w-full justify-center md:col-start-auto md:col-end-auto md:space-x-0  md:space-y-2 md:flex-col'>
                    {returnButtons(data?.order?.order_id)}
                </td>
            </tr>
        </>
    );
}

export default BuyTableInfo;