import React,{useState,useEffect} from 'react';
import { useTitle } from '../Misc/Misc';
import { Link,useLocation } from 'react-router-dom';
import GatewayLoader from '../Loader/GatewayLoader';
import GetAPIMethods from '../../APIMethods/GetAPIMethod';
import UserDetails from './UserVerificationComponents/UserDetails';

function UserVerification(props) {
    const search = useLocation().search;
    useTitle(`Gateway Exchange - Customer KYC Verification`);

    const [filterBy,setFilterBy] = useState("Pending");
    const [request,setRequests] = useState({loading:false,data:null,error:false,});

    const userList = GetAPIMethods(`user-list/?profile_status=${filterBy}`);

    useEffect(()=>{
        const params = new URLSearchParams(search);
        const status = params.get('profile_status');

        if (status) {
            setFilterBy(status)
        }
       
    },[search])

    useEffect(()=>{
        if (request.data?.success) {
            window.location.reload()
        }
    },[request.data])



    return (
        <div className='w-full mx-auto my-32 md:w-[90%]'>
            {(userList.loading || request.loading) && <GatewayLoader/>}
            <div className='w-full mt-11'>
                <div className='w-full'>
                    <div className='flex justify-evenly max-w-lg items-center place-content-center'>
                        <Link to={`?profile_status=Pending`}>
                            <div onClick={()=>{setFilterBy("Pending")}} className={`${filterBy === "Pending"?"border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Pending ({userList.data?.pending})</h3>
                            </div>
                        </Link>

                        <Link to={`?profile_status=Valid`}>
                            <div onClick={()=>{setFilterBy("Valid")}} className={`${filterBy === "Valid"?"border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Approved ({userList.data?.valid})</h3>
                            </div>
                        </Link>

                        <Link to={`?profile_status=Invalid`}>
                            <div onClick={()=>{setFilterBy("Invalid")}} className={`${filterBy === "Invalid"?"border-b-4 border-b-[#ffa500]":""} cursor-pointer text-sm py-2 md:text-lg`}>
                                <h3>Declined ({userList.data?.invalid})</h3>
                            </div>
                        </Link>
                    </div>
                </div>

                <table className='w-full'>
                <thead className='bg-[#FAFAFA] w-full h-20 flex place-content-center p-5'>
                    <tr className='grid grid-cols-3 justify-evenly w-full h-full items-center text-xs md:text-base md:grid-cols-6'>
                        <th>Customer Name</th>
                        <th>Verification Photo</th>
                        <th className='hidden md:block'>D.O.B</th>
                        <th className='hidden md:block'>Email</th>
                        <th>Verification Number</th>
                    </tr>
                </thead>
                <tbody className='w-full'>
                    {
                        userList.data?.users?.map((user,key)=>{
                            return(<UserDetails userData={user} setRequests={setRequests} key={key}  view={filterBy}/>)
                        })
                    }

                </tbody>

            
            </table>

            </div>
            
        </div>
    );
}

export default UserVerification;